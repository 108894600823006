import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Basic workflow`}</h2>
    <p>{`The basic workflow of a third-party client (application or device) is the following.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Authorize the user:`}</strong>{` Obtain and validate the user’s credentials (username and password). The client must never store these credentials, only pass it to the server for authorization.`}</li>
      <li parentName="ol"><strong parentName="li">{`Obtain an access token:`}</strong>{` The third-party client application can obtain an access token if the user’s credentials were entered correctly.`}</li>
      <li parentName="ol"><strong parentName="li">{`Access the user’s resources:`}</strong>{` The client can use IBM Video Streaming’s REST API or the IBM Video Streaming Broadcasting Library to access the user’s resources (data) or to broadcast to the user’s channel. The client authorizes itself using the access token, with methods described by The OAuth 2.0 Authorization Framework (`}<a parentName="li" {...{
          "href": "https://tools.ietf.org/html/rfc6749"
        }}>{`https://tools.ietf.org/html/rfc6749`}</a>{`).`}</li>
      <li parentName="ol"><strong parentName="li">{`Refresh the token, if necessary:`}</strong>{` By default, access tokens expires within a day. If your application needs access to an IBM Video Streaming API beyond this period, it can use the refresh token (that is obtained when requesting the access token for the first time) to obtain new access tokens.`}</li>
    </ol>
    <h2>{`Endpoints`}</h2>
    <p>{`The webpages and HTTP services invoked in during an authorization process are called endpoints.
IBM Video Streaming has the following endpoints:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Authorization endpoint:`}</strong>{` this is the webpage where the user enters his/her credentials.`}</li>
      <li parentName="ul"><strong parentName="li">{`Token endpoint:`}</strong>{` this is the HTTP service where the access token can be obtained in an authorization code, client credentials or refresh token flow.`}</li>
    </ul>
    <h2>{`Access Token`}</h2>
    <p>{`A token can be used to access the user’s resources, up until the token expires or the user revokes it through the token revocation endpoint.`}</p>
    <h3>{`Token scopes, expiration`}</h3>
    <p>{`Access tokens expire in a day and can access only a limited amount of resources. In the authorization process, the client can request extra permissions (scopes) from the user to overcome these limitations. These requests are shown to the user on the authorization endpoint.`}</p>
    <p>{`IBM Video Streaming currently supports the following scope for access tokens:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`SCOPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`broadcaster`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If this scope is enabled, the access token can be used for broadcasting to the IBM Video Streaming Broadcasting Library.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The basic (and required) scope for OpenId Connect. This scope indicates that an application intends to obtain the application user’s identity. Beyond that, an application can ask for additional scopes by listing the requested scope names in the scope parameter, separated by spaces.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the email claim, which contains the user’s email address, and email_verified status, which is a boolean indicating whether the email address was verified by the authentication service.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      